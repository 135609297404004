// src/config/api.js

// development: 'http://localhost:5002'
// production: 'https://yswams.com'
// http://198.199.121.13

  const API_CONFIG = {
    // BASE_URL: 'http://localhost:5002',
    BASE_URL: 'https://docease-webapp.yswams.com',
    ENDPOINTS: {
      DOWNLOAD: '/api/download',
      GOOGLE_AUTH: '/api/auth/google',
      USAGE: '/api/usage',
      UPLOAD: '/api/upload',
      FORMAT: '/format',
    }
  };
  
  export const getApiUrl = (endpoint) => `${API_CONFIG.BASE_URL}${endpoint}`;
  export default API_CONFIG;