// File: frontend/src/components/home/TypingAnimation.jsx

import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypingAnimation = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        'Hello World!',
        'This is your document.',
        'Watch it transform.',
        '**Bold text**, *italic text*, and `code`.',
        '# Heading Level 1',
        '## Heading Level 2',
        '- Bullet list',
        '> Blockquote',
        '| Table | Example |',
        '|-------|---------|',
      ],
      typeSpeed: 50,
      backSpeed: 25,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-md shadow-md p-6">
      <pre className="text-gray-800 dark:text-gray-100 whitespace-pre-wrap">
        <span ref={el} />
      </pre>
    </div>
  );
};

export default TypingAnimation;
