// File: frontend/src/components/home/HowItWorksSection.jsx

import React from 'react';
import { motion } from 'framer-motion';

const steps = [
  {
    number: 1,
    title: 'Upload or Paste Your Text',
    description:
      'Easily upload your document or paste your text directly into the editor.',
  },
  {
    number: 2,
    title: 'Choose Your Format',
    description: 'Select the desired output format: PDF, Markdown, or Word.',
  },
  {
    number: 3,
    title: 'Preview Changes',
    description: 'Instantly see the formatted document in the preview pane.',
  },
  {
    number: 4,
    title: 'Download the Result',
    description:
      'Download your beautifully formatted document with a single click.',
  },
];

const HowItWorksSection = () => {
  return (
    <section className="py-20 bg-white dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          How It Works
        </motion.h2>
        <div className="flex flex-col md:flex-row md:justify-center md:space-x-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex-1 mb-8 md:mb-0"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <div className="flex flex-col items-center md:items-start text-center md:text-left">
                <div className="flex items-center justify-center w-16 h-16 bg-purple-600 text-white rounded-full mb-4">
                  <span className="text-2xl font-bold">{step.number}</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  {step.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
