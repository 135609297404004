// src/components/formatter/OutputSection.jsx

import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import clsx from 'clsx';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // Add this import at the top

const OutputSection = ({
  formattedText,
  isProcessing,
  progress,
  settings,
  updateSettings,
  toggleToC,
  isToCVisible,
}) => {
  const outputContainerRef = useRef(null);

  useEffect(() => {
    if (!isProcessing && outputContainerRef.current) {
      outputContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isProcessing]);

  const renderers = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={oneDark}
          language={match[1]}
          PreTag="div"
          className="rounded-md overflow-hidden my-4"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code
          className="bg-gray-200 dark:bg-gray.700 dark:text-red-300 px-1 py-0.5 rounded text-sm"
          {...props}
        >
          {children}
        </code>
      );
    },
    img: ({ node, ...props }) => (
      <div className="flex flex-col items-center my-4">
        <img
          className="max-w-full h-auto rounded-md shadow-md"
          {...props}
        />
      </div>
    ),
    table: ({ node, ...props }) => (
      <table className="table-auto w-full my-4" {...props} />
    ),
    thead: ({ node, ...props }) => (
      <thead className="bg-gray-200 dark:bg-gray-700" {...props} />
    ),
    tbody: ({ node, ...props }) => (
      <tbody {...props} />
    ),
    th: ({ node, ...props }) => (
      <th className="px-4 py-2 border-b border-gray-300 dark:border-gray-600 text-left" {...props} />
    ),
    td: ({ node, ...props }) => (
      <td className="px-4 py-2 border-b border-gray-300 dark:border-gray-600" {...props} />
    ),
    blockquote: ({ node, ...props }) => (
      <blockquote className="border-l-4 border-purple-500 pl-4 italic my-4" {...props} />
    ),
    h1: ({ node, ...props }) => (
      <h1 className="text-2xl font-normal text-primary-700 dark:text-primary-400 my-2" {...props} />
    ),
    h2: ({ node, ...props }) => (
      <h2 className="text-xl font-normal text-primary-600 dark:text-primary-300 my-2" {...props} />
    ),
    h3: ({ node, ...props }) => (
      <h3 className="text-lg font-normal text-primary-500 dark:text-primary-200 my-2" {...props} />
    ),
    h4: ({ node, ...props }) => (
      <h4 className="text-base font-normal text-primary-400 dark:text-primary-100 my-2" {...props} />
    ),
    p: ({ node, ...props }) => (
      <p className="my-2" {...props} />
    ),
    ul: ({ node, ...props }) => (
      <ul className="list-disc list-outside my-2 pl-6" {...props} />
    ),
    ol: ({ node, ...props }) => (
      <ol className="list-decimal list-outside my-2 pl-6" {...props} />
    ),
    li: ({ node, ...props }) => (
      <li className="mb-1" {...props} />
    ),
  };

  return (
    <div className="flex flex-col h-full rounded-xl bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 mx-2">
      <div className="flex items-center justify-between px-6 py-2.5 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200">Formatted Output</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={toggleToC}
            disabled={!formattedText}
            className={clsx(
              'inline-flex items-center justify-center h-8 w-8 rounded-lg transition-all duration-200 border border-gray-200 dark:border-gray-700',
              {
                'text-primary-600 hover:bg-primary-50 dark:text-primary-400 dark:hover:bg-primary-900/40':
                  formattedText && !isToCVisible,
                'text-gray-400 cursor-not-allowed': !formattedText,
                'animate-glow': !isToCVisible && formattedText,
              }
            )}
            title="Toggle Table of Contents"
          >
            <ListBulletIcon className="h-4 w-4" />
          </button>
        </div>
      </div>

      <div
        ref={outputContainerRef}
        className="flex-1 p-6 pt-1 overflow-auto bg-white dark:bg-gray-800 prose dark:prose-invert max-w-none"
      >
        {formattedText ? (
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings, rehypeKatex]}
            components={renderers}
          >
            {formattedText}
          </ReactMarkdown>
        ) : (
          !isProcessing && (
            <div className="flex items-center justify-center h-full text-gray-400 dark:text-gray-500">
              <p className="text-lg">Your formatted text will appear here</p>
            </div>
          )
        )}

        {isProcessing && (
          <div className="flex items-center justify-center h-full">
            <div className="flex items-center gap-3 text-primary-600 dark:text-primary-400">
              <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <span>Processing...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OutputSection;
