import React from 'react';

const ExtensionPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-purple-600 mb-4">Chrome Extension Page</h1>
      <p className="text-lg text-gray-600">
        Coming soon!
      </p>
    </div>
  );
};

export default ExtensionPage;
