// docease/src/components/formatter/DiffViewerModal.jsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import {
  ClipboardIcon,
  XMarkIcon,
  ArrowsRightLeftIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';

Modal.setAppElement('#root');

const DiffViewerModal = ({
  isOpen,
  onRequestClose,
  originalText,
  formattedText,
}) => {
  const [splitView, setSplitView] = useState(true);
  const [showPlainText, setShowPlainText] = useState(true);

  // Enhanced stripMarkdown function to remove Markdown syntax without affecting hyphens in content and remove comments
  const stripMarkdown = (text) => {
    return text
      .replace(/<!--[\s\S]*?-->/g, '') // Remove HTML comments
      .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1') // Remove links but keep the text
      .replace(/`{1,3}[^`]*`{1,3}/g, '') // Remove inline code and code blocks
      .replace(/#+\s?/g, '') // Remove headings
      .replace(/(\*\*|__)(.*?)\1/g, '$2') // Remove bold
      .replace(/(\*|_)(.*?)\1/g, '$2') // Remove italics
      .replace(/~~(.*?)~~/g, '$1') // Remove strikethrough
      .replace(/>\s?/g, '') // Remove blockquotes
      .replace(/^\s*-{3,}\s*$/gm, '') // Remove horizontal rules (three or more hyphens)
      .replace(/^\s*\n/gm, '') // Remove empty lines
      .trim();
  };

  const copyOriginal = () => {
    navigator.clipboard.writeText(originalText);
    alert('Original text copied to clipboard!');
  };

  const copyFormatted = () => {
    const textToCopy = showPlainText ? stripMarkdown(formattedText) : formattedText;
    navigator.clipboard.writeText(textToCopy);
    alert('Formatted text copied to clipboard!');
  };

  const characterDifference = showPlainText
    ? stripMarkdown(formattedText).length - originalText.length
    : formattedText.length - originalText.length;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '1200px',
      height: '90vh',
      padding: '20px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f9fafb', // Tailwind's gray-100
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      transition: 'opacity 200ms ease-in-out',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="View Changes"
      style={customStyles}
      closeTimeoutMS={200}
    >
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="flex-none flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-indigo-600">Changes</h2>
          <button
            onClick={onRequestClose}
            className="text-gray-700 hover:text-gray-900 transition-colors duration-200"
            title="Close"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        {/* Control Buttons */}
        <div className="flex-none flex items-center justify-between mb-4">
          <div className="flex space-x-2">
            {/* Toggle Split/Inline View */}
            <button
              onClick={() => setSplitView(!splitView)}
              className="flex items-center px-4 py-2 rounded-md border border-indigo-500 text-indigo-600 font-semibold hover:bg-indigo-50 transition-colors duration-200"
              title="Toggle Split/Inline View"
            >
              <ArrowsRightLeftIcon className="h-5 w-5 mr-2" />
              {splitView ? 'Switch to Inline View' : 'Switch to Split View'}
            </button>

            {/* Toggle Plain Text / Markdown View */}
            <button
              onClick={() => setShowPlainText(!showPlainText)}
              className="flex items-center px-4 py-2 rounded-md border border-indigo-500 text-indigo-600 font-semibold hover:bg-indigo-50 transition-colors duration-200"
              title="Toggle Plain Text/View Markdown"
            >
              {showPlainText ? (
                <>
                  <EyeIcon className="h-5 w-5 mr-2" />
                  Show Markdown
                </>
              ) : (
                <>
                  <EyeSlashIcon className="h-5 w-5 mr-2" />
                  Show Plain Text
                </>
              )}
            </button>
          </div>

          {/* Character Count Difference */}
          <div className="text-gray-700 font-medium">
            Character Count Difference: {characterDifference > 0 ? '+' : ''}
            {characterDifference}
          </div>

          {/* Copy Buttons */}
          <div className="flex space-x-2">
            <button
              onClick={copyOriginal}
              className="flex items-center px-4 py-2 rounded-md border border-indigo-500 text-indigo-600 font-semibold hover:bg-indigo-50 transition-colors duration-200"
              title="Copy Original Text"
            >
              <ClipboardIcon className="h-5 w-5 mr-1" />
              Copy Original
            </button>
            <button
              onClick={copyFormatted}
              className="flex items-center px-4 py-2 rounded-md border border-indigo-500 text-indigo-600 font-semibold hover:bg-indigo-50 transition-colors duration-200"
              title="Copy Formatted Text"
            >
              <ClipboardIcon className="h-5 w-5 mr-1" />
              Copy Formatted
            </button>
          </div>
        </div>

        {/* Diff Viewer */}
        <div className="flex-1 min-h-0 border border-indigo-300 rounded-md overflow-auto shadow-inner bg-white">
          <ReactDiffViewer
            oldValue={originalText}
            newValue={
              showPlainText ? stripMarkdown(formattedText) : formattedText
            }
            splitView={splitView}
            compareMethod={DiffMethod.WORDS_WITH_SPACE}
            showDiffOnly={false}
            leftTitle="Original Text"
            rightTitle={showPlainText ? 'Formatted Text (Plain)' : 'Formatted Text (Markdown)'}
            useDarkTheme={false}
            hideLineNumbers={false}
            styles={{
              variables: {
                light: {
                  addedBackground: '#e6ffec',
                  addedColor: '#24292e',
                  removedBackground: '#ffebe9',
                  removedColor: '#24292e',
                  wordAddedBackground: '#acf2bd',
                  wordRemovedBackground: '#fdb8c0',
                  addedGutterBackground: '#cdffd8',
                  removedGutterBackground: '#ffdce0',
                },
              },
              contentText: {
                fontSize: '16px',
                lineHeight: '1.6',
                maxWidth: '100%',
                whiteSpace: 'pre-wrap',
              },
              line: {
                wordBreak: 'break-word',
              },
              gutter: {
                minWidth: '40px',
              },
              diffContainer: {
                borderRadius: '8px',
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DiffViewerModal;
