// File: frontend/src/components/home/HeroSection.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import TypingAnimation from './TypingAnimation';
import { motion } from 'framer-motion';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    // Trigger analytics event
    console.log('Analytics Event: Get Started Button Clicked');
    // Navigate to the formatter page
    navigate('/formatter');
  };

  const fadeRight = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const fadeLeft = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <section className="bg-gradient-to-r from-purple-600 to-indigo-600 dark:from-gray-800 dark:to-gray-900 text-white py-20">
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
        {/* Text content */}
        <motion.div
          className="md:w-1/2"
          variants={fadeRight}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-4 leading-tight">
            Effortless <span className="text-yellow-300">AI-Powered</span> Document Formatting
          </h1>
          <p className="text-lg md:text-xl mb-6">
            Transform your documents with intelligent formatting. Save time and enhance productivity with DocEase.
          </p>
          <button
            onClick={handleGetStarted}
            className="inline-flex items-center px-6 py-3 bg-white text-purple-600 font-semibold rounded-md hover:bg-gray-100 transition duration-300"
          >
            Get Started
            <ArrowRightIcon className="h-5 w-5 ml-2" />
          </button>
        </motion.div>
        {/* Animated Demo */}
        <motion.div
          className="md:w-1/2 mt-10 md:mt-0 flex justify-center"
          variants={fadeLeft}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8 }}
        >
          <TypingAnimation />
        </motion.div>
      </div>
    </section>
  );
};

export default HeroSection;
