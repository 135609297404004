// File: frontend/src/components/home/FeaturesSection.jsx

import React from 'react';
import { motion } from 'framer-motion';
import {
  SparklesIcon,
  DocumentDuplicateIcon,
  CloudArrowUpIcon,
  ViewColumnsIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/outline';

const features = [
  {
    icon: <SparklesIcon className="h-12 w-12 text-purple-600" />,
    title: 'Real-time Formatting Preview',
    description: 'See the changes as you type with our live preview feature.',
  },
  {
    icon: <DocumentDuplicateIcon className="h-12 w-12 text-purple-600" />,
    title: 'Multiple Format Support',
    description: 'Export your documents in PDF, Markdown, or Word formats.',
  },
  {
    icon: <CloudArrowUpIcon className="h-12 w-12 text-purple-600" />,
    title: 'Drag-and-Drop Functionality',
    description: 'Easily upload your files by dragging them into the app.',
  },
  {
    icon: <ViewColumnsIcon className="h-12 w-12 text-purple-600" />,
    title: 'Split-View Interface',
    description: 'Edit and preview your documents side by side.',
  },
  {
    icon: <ArrowsRightLeftIcon className="h-12 w-12 text-purple-600" />,
    title: 'Diff Checking',
    description: 'Compare original and formatted documents effortlessly.',
  },
];

const FeaturesSection = () => {
  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Powerful Features
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <div className="flex items-center mb-4">
                <div className="flex-shrink-0">{feature.icon}</div>
                <h3 className="ml-4 text-xl font-semibold text-gray-800 dark:text-white">
                  {feature.title}
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
