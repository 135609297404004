// File: frontend/src/components/home/WhyDocEaseSection.jsx

import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import DocumentTransformationAnimation from './DocumentTransformationAnimation';
import { motion } from 'framer-motion';

const benefits = [
  'Save time with automated formatting',
  'Improve document consistency',
  'User-friendly interface',
  'AI-powered accuracy',
  'Seamless integration with your workflow',
];

const WhyDocEaseSection = () => {
  return (
    <section className="py-24 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-center mb-16 text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Why Choose DocEase
        </motion.h2>
        
        <div className="flex flex-col md:flex-row items-center gap-12">
          {/* Animation Component */}
          <motion.div
            className="md:w-1/2 mb-12 md:mb-0"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <DocumentTransformationAnimation />
          </motion.div>
          
          {/* Benefits List */}
          <motion.div
            className="md:w-1/2"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <ul className="space-y-6">
              {benefits.map((benefit, index) => (
                <motion.li
                  key={index}
                  className="flex items-start space-x-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                >
                  <CheckCircleIcon className="h-6 w-6 text-purple-600 flex-shrink-0 mt-1" />
                  <span className="text-lg text-gray-700 dark:text-gray-300">
                    {benefit}
                  </span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WhyDocEaseSection;
