// File: frontend/src/components/home/DocumentTransformationAnimation.jsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiCode, FiAlertCircle, FiCheckCircle, FiList } from 'react-icons/fi';

const DocumentPreview = () => {
  const [isFormatted, setIsFormatted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeSection, setActiveSection] = useState(null);
  const [showTransformation, setShowTransformation] = useState(false);

  useEffect(() => {
    const loadTimer = setTimeout(() => setIsLoading(false), 1000);
    const transformTimer = setTimeout(() => setShowTransformation(true), 2000);
    const formatTimer = setTimeout(() => {
      setIsFormatted(true);
      setShowTransformation(false);
    }, 4000);
    
    return () => {
      clearTimeout(loadTimer);
      clearTimeout(transformTimer);
      clearTimeout(formatTimer);
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.1 
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };

  const transformationVariants = {
    initial: {
      opacity: 0,
      scale: 1,
      y: 0,
    },
    animate: {
      opacity: [0, 1, 1, 0],
      scale: [1, 1.1, 1.1, 1],
      y: [0, -10, -10, 0],
      transition: {
        duration: 2,
        times: [0, 0.3, 0.7, 1],
        ease: "easeInOut",
      }
    }
  };

  const glowVariants = {
    initial: { scale: 0.8, opacity: 0 },
    animate: { 
      scale: 1.2, 
      opacity: [0, 0.5, 0],
      transition: { 
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  };

  const unformattedText = `technical documentation
markdown syntax guide

code example:
function calculateFibonacci(n) {
return n <= 1 ? n : fib(n-1) + fib(n-2)
}

important note!!!
this is a critical warning message that needs attention

features to implement:
* authentication system
* database integration
* api endpoints
* user dashboard
* error handling`;

  const formattedContent = (
    <motion.div 
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6 text-sm"
    >
      <motion.h1 
        variants={itemVariants}
        className="text-3xl font-bold text-gray-800 bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent"
      >
        Technical Documentation
      </motion.h1>

      <motion.div 
        variants={itemVariants}
        className="code-section group"
        onHoverStart={() => setActiveSection('code')}
        onHoverEnd={() => setActiveSection(null)}
      >
        <div className="flex items-center gap-2 mb-2">
          <FiCode className="text-purple-500" />
          <h2 className="text-xl font-semibold text-gray-700">Code Example</h2>
        </div>
        <div className="relative">
          <pre className="bg-gray-900 p-4 rounded-lg shadow-xl font-mono text-sm overflow-x-auto">
            <code className="text-gray-100">
              {`function calculateFibonacci(n) {
    return n <= 1 ? n : fib(n-1) + fib(n-2);
    }`}
            </code>
          </pre>
          <div className="absolute inset-0 bg-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg" />
        </div>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="warning-section"
        onHoverStart={() => setActiveSection('warning')}
        onHoverEnd={() => setActiveSection(null)}
      >
        <div className="flex items-center gap-2 mb-2">
          <FiAlertCircle className="text-amber-500" />
          <h2 className="text-xl font-semibold text-gray-700">Important Note</h2>
        </div>
        <div className="relative overflow-hidden">
          <blockquote className="border-l-4 border-amber-500 pl-4 py-3 bg-amber-50 rounded-lg">
            <p className="text-gray-700">This is a critical warning message that needs attention</p>
          </blockquote>
          {activeSection === 'warning' && (
            <motion.div
              variants={glowVariants}
              initial="initial"
              animate="animate"
              className="absolute inset-0 bg-amber-400/20 blur-xl"
            />
          )}
        </div>
      </motion.div>

      <motion.div 
        variants={itemVariants}
        className="features-section"
      >
        <div className="flex items-center gap-2 mb-2">
          <FiList className="text-green-500" />
          <h2 className="text-xl font-semibold text-gray-700">Features</h2>
        </div>
        <div className="bg-white rounded-lg p-4 shadow-lg border border-gray-100">
          <ul className="grid grid-cols-2 gap-3">
            {[
              'Authentication System',
              'Database Integration',
              'API Endpoints',
              'User Dashboard',
              'Error Handling'
            ].map((feature, index) => (
              <motion.li
                key={feature}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center gap-2 text-gray-600"
              >
                <FiCheckCircle className="text-green-500" />
                {feature}
              </motion.li>
            ))}
          </ul>
        </div>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="relative bg-white rounded-xl shadow-2xl p-6 h-[500px] overflow-hidden">
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 flex items-center justify-center bg-white z-50"
          >
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-8 h-8 border-4 border-purple-500 border-t-transparent rounded-full"
            />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isFormatted ? 0 : 1 }}
        transition={{ duration: 1 }}
        className="font-mono whitespace-pre-wrap text-gray-600"
      >
        {unformattedText}
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isFormatted ? 1 : 0 }}
        transition={{ duration: 1 }}
        className="absolute top-6 left-6 right-6"
      >
        {formattedContent}
      </motion.div>

      {/* Enhanced transformation effect */}
      <AnimatePresence>
        {showTransformation && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0 bg-purple-500/10 backdrop-blur-sm z-10"
            />
            <motion.div
              variants={transformationVariants}
              initial="initial"
              animate="animate"
              exit="initial"
              className="absolute inset-0 flex items-center justify-center z-20"
            >
              <div className="text-purple-600 font-bold text-2xl">
                Transforming Content...
              </div>
            </motion.div>
            <motion.div
              initial={{ y: "-100%" }}
              animate={{ y: "100%" }}
              exit={{ y: "100%" }}
              transition={{ duration: 2, ease: "easeInOut" }}
              className="absolute inset-0 bg-gradient-to-b from-purple-500/30 via-purple-500/40 to-purple-500/30 blur-xl z-10"
            />
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DocumentPreview;
