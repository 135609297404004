// File: frontend/src/components/home/TestimonialsSection.jsx

import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  {
    quote:
      'DocEase has revolutionized the way we format our documents. It saves us hours each week!',
    name: 'Jane Doe',
    title: 'Content Manager at Acme Corp',
    image: '/images/testimonial1.jpg',
  },
  {
    quote:
      'The AI formatting is incredibly accurate. Our documents have never looked better.',
    name: 'John Smith',
    title: 'Technical Writer at TechFlow',
    image: '/images/testimonial2.jpg',
  },
  {
    quote:
      'An essential tool for any professional dealing with document formatting.',
    name: 'Emily Johnson',
    title: 'Freelance Editor',
    image: '/images/testimonial3.jpg',
  },
];

const TestimonialsSection = () => {
  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          What Our Users Say
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <p className="text-gray-700 dark:text-gray-300 italic mb-4">
                "{testimonial.quote}"
              </p>
              <div className="flex items-center">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="h-12 w-12 rounded-full mr-4"
                />
                <div>
                  <p className="text-gray-800 dark:text-white font-semibold">
                    {testimonial.name}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 text-sm">
                    {testimonial.title}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
