import React, { useState, useEffect, useContext } from 'react';
import InputSection from '../components/formatter/InputSection';
import OutputSection from '../components/formatter/OutputSection';
import TopControls from '../components/formatter/TopControls';
import DiffViewerModal from '../components/formatter/DiffViewerModal';
import HeaderTracker from '../components/formatter/HeaderTracker';
import { io } from 'socket.io-client';
import { AuthContext } from '../contexts/AuthContext';
import Modal from 'react-modal';
import API_CONFIG, { getApiUrl } from '../config/api';
import TurndownService from 'turndown';

Modal.setAppElement('#root');

const FormatterPage = () => {
  const [inputText, setInputText] = useState('');
  const [formattedText, setFormattedText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [socket, setSocket] = useState(null);
  const [isDiffModalOpen, setIsDiffModalOpen] = useState(false);
  const [isFormatted, setIsFormatted] = useState(false);
  const [isToCVisible, setIsToCVisible] = useState(false);
  const [tocTimeoutId, setToCTimeoutId] = useState(null);
  const [settings, setSettings] = useState({
    fontFamily: 'Arial',
    fontSize: 16,
  });
  const { user, login, usage, fetchUsage } = useContext(AuthContext);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const newSocket = io(getApiUrl(API_CONFIG.ENDPOINTS.FORMAT));
    newSocket.on('connect', () => console.log('Connected to server'));
    newSocket.on('disconnect', () => console.log('Disconnected from server'));
    newSocket.on('formatted_chunk', (data) => setFormattedText((prev) => prev + data.chunk));
    newSocket.on('progress', (data) => setProgress(data.progress));
    newSocket.on('done', () => {
      setIsProcessing(false);
      setProgress(100);
      if (user && user.id) fetchUsage(user.id);
    });
    newSocket.on('error', (error) => {
      console.error('Socket error:', error);
      setIsProcessing(false);
      alert(`Error: ${error.error}`);
    });
    setSocket(newSocket);
    return () => newSocket.disconnect();
  }, [user, fetchUsage]);

  useEffect(() => {
    if (!user) {
      const storedWordCount = localStorage.getItem('currentWordCount');
      setCurrentWordCount(parseInt(storedWordCount, 10) || 0);
    }
  }, [user]);

  const updateWordCountLocal = (newWords) => {
    if (!user) {
      const updatedCount = currentWordCount + newWords;
      setCurrentWordCount(updatedCount);
      localStorage.setItem('currentWordCount', updatedCount.toString());
      if (updatedCount >= 2000) handleShowLimitModal();
    }
  };

  const handleShowLimitModal = () => setShowLimitModal(true);
  const handleCloseLimitModal = () => setShowLimitModal(false);
  const handleShowUpgradeModal = () => setShowUpgradeModal(true);
  const handleCloseUpgradeModal = () => setShowUpgradeModal(false);

  const handleFormat = () => {
    if (!socket) {
      console.error('Socket not initialized');
      return;
    }

    const wordsToFormat = inputText.trim().split(/\s+/).filter(Boolean).length;

    const turndownService = new TurndownService();
    const markdownText = turndownService.turndown(inputText);

    if (user) {
      if (usage.word_count + wordsToFormat > 5000) {
        handleShowUpgradeModal();
        return;
      }
      socket.emit('format', { text: markdownText, settings: settings, user_id: user.id });
    } else {
      if (currentWordCount + wordsToFormat > 2000) {
        handleShowLimitModal();
        return;
      }
      socket.emit('format', { text: markdownText, settings: settings, user_id: null });
      updateWordCountLocal(wordsToFormat);
    }

    setIsFormatted(true);
    setIsProcessing(true);
    setFormattedText('');
    setProgress(0);
    setIsToCVisible(true);

    if (tocTimeoutId) clearTimeout(tocTimeoutId);

    const timeoutId = setTimeout(() => {
      setIsToCVisible(false);
      setToCTimeoutId(null);
    }, 4000);

    setToCTimeoutId(timeoutId);
  };

  const handleViewChanges = () => setIsDiffModalOpen(true);
  const handleToggleToC = () => setIsToCVisible(!isToCVisible);

  useEffect(() => {
    return () => {
      if (tocTimeoutId) clearTimeout(tocTimeoutId);
    };
  }, [tocTimeoutId]);

  const getWidths = () => {
    if (!isFormatted) return { input: '50%', output: '50%', toc: '0%' };
    if (isToCVisible) return { input: '25%', output: '50%', toc: '25%' };
    return { input: '50%', output: '50%', toc: '0%' };
  };

  const widths = getWidths();

  return (
    <div className="flex flex-col relative min-h-[90vh] h-[90vh] mt-16">
      {/* Main content area with reduced height to accommodate controls */}
      <div className="flex flex-grow h-[calc(100%-5rem)] overflow-hidden">
        <div className="transition-all duration-500 ease-in-out h-full" style={{ width: widths.input }}>
          <InputSection
            inputText={inputText}
            setInputText={setInputText}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
          />
        </div>
        <div className="transition-all duration-500 ease-in-out h-full" style={{ width: widths.output }}>
          <OutputSection
            formattedText={formattedText}
            isProcessing={isProcessing}
            progress={progress}
            settings={settings}
            updateSettings={setSettings}
            toggleToC={handleToggleToC}
            isToCVisible={isToCVisible}
          />
        </div>
        <div className={`transition-all duration-500 ease-in-out h-full overflow-hidden ${isToCVisible ? 'opacity-100' : 'opacity-0'}`} style={{ width: widths.toc }}>
          <HeaderTracker
            formattedText={formattedText}
            isOpen={isToCVisible}
            toggleToC={handleToggleToC}
          />
        </div>
      </div>

      {/* Sticky controls at the bottom */}
      <div className="sticky bottom-0 left-0 right-0 z-50">
        <TopControls 
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          formattedText={formattedText}
          handleFormat={handleFormat}
          handleViewChanges={handleViewChanges}
          setInputText={setInputText}
          user={user}
          login={login}
          settings={settings}
        />
      </div>

      {showLimitModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Weekly limit reached</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">You have reached the weekly word limit of 2000 words. Please sign in for unlimited access.</p>
            <div className="flex justify-end space-x-2">
              <button onClick={handleCloseLimitModal} className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600 transition-colors duration-200">Cancel</button>
              {!user && <button onClick={login} className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md">Sign In</button>}
            </div>
          </div>
        </div>
      )}

      {showUpgradeModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Upgrade to Pro</h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">You have reached your weekly word limit of 5000 words. Upgrade to pro to increase your limit and unlock more features.</p>
            <div className="flex justify-end space-x-2">
              <button onClick={handleCloseUpgradeModal} className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600 transition-colors duration-200">Cancel</button>
              <button onClick={() => alert('Upgrade to pro feature coming soon!')} className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md">Upgrade</button>
            </div>
          </div>
        </div>
      )}

      {isDiffModalOpen && (
        <DiffViewerModal
          isOpen={isDiffModalOpen}
          onRequestClose={() => setIsDiffModalOpen(false)}
          originalText={inputText}
          formattedText={formattedText}
        />
      )}
    </div>
  );
};

export default FormatterPage;