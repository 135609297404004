// src/components/formatter/TopControls.jsx

import React, { useState, useRef, useEffect, useContext } from 'react';
import { ArrowUpTrayIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../../config/api';
import GooglePicker from 'react-google-picker';
import { AuthContext } from '../../contexts/AuthContext';
import { GOOGLE_CLIENT_ID, GOOGLE_DEVELOPER_KEY } from '../../config/google';
import AnimatedFormatButton from './AnimatedFormatButton';

const TopControls = ({ 
  isProcessing, 
  setIsProcessing, 
  formattedText, 
  handleFormat,
  handleViewChanges,
  setInputText,
  user,
  login,
  settings 
}) => {
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const [isStylesMenuOpen, setIsStylesMenuOpen] = useState(false);
  const downloadMenuRef = useRef(null);
  const stylesMenuRef = useRef(null);
  const { user: authUser } = useContext(AuthContext);

  const onDrop = (acceptedFiles) => {
    if (isProcessing || !user) return;

    acceptedFiles.forEach((file) => {
      if (!user && (file.type === 'application/pdf' || 
          file.type === 'application/msword' || 
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.name.endsWith('.doc') ||
          file.name.endsWith('.docx'))) {
        alert('Please sign in to upload PDF or Word files.');
        return;
      }

      if (file.type === 'text/plain' || file.name.endsWith('.md')) {
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result;
          setInputText((prevText) => prevText + '\n' + text);
        };
        reader.readAsText(file);
      } else if (user && (file.type === 'application/pdf' ||
                file.type === 'application/msword' ||
                file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        const formData = new FormData();
        formData.append('file', file);
        
        setIsProcessing(true);
        fetch(getApiUrl(API_CONFIG.ENDPOINTS.UPLOAD), {
          method: 'POST',
          body: formData,
        })
          .then((response) => {
            setIsProcessing(false);
            if (!response.ok) throw new Error('Network response was not ok');
            return response.json();
          })
          .then((data) => {
            const content = data.content;
            setInputText((prevText) => prevText + '\n' + content);
          })
          .catch((error) => {
            setIsProcessing(false);
            console.error('Error uploading file:', error);
            alert('Error processing file. Please try again.');
          });
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: user ? 
      'text/plain,text/markdown,.md,.txt,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
      'text/plain,text/markdown,.md,.txt',
    disabled: isProcessing || !user,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (downloadMenuRef.current && !downloadMenuRef.current.contains(event.target)) {
        setIsDownloadMenuOpen(false);
      }
      if (stylesMenuRef.current && !stylesMenuRef.current.contains(event.target)) {
        setIsStylesMenuOpen(false);
      }
    };

    if (isDownloadMenuOpen || isStylesMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDownloadMenuOpen, isStylesMenuOpen]);

  const handleDownload = async (format) => {
    if (!formattedText) return;
    try {
      const response = await fetch(getApiUrl(API_CONFIG.ENDPOINTS.DOWNLOAD), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: formattedText, format, settings }),
      });

      if (!response.ok) throw new Error('Failed to download the file.');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `formatted_document.${format}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      setIsDownloadMenuOpen(false);
    } catch (error) {
      console.error('Download error:', error);
      alert('Failed to download the file.');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formattedText);
    alert('Copied to clipboard!');
  };

  return (
    <div className="w-full px-6 py-4 mt-4 mb-6 bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-purple-100 dark:border-gray-700">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <button
            {...getRootProps()}
            className={`inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
              ${isDragActive 
                ? 'bg-purple-100 text-purple-700 dark:bg-purple-900/30 dark:text-purple-300' 
                : 'bg-purple-50 text-purple-700 hover:bg-purple-100 dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30'
              } ${!user ? 'opacity-50 cursor-not-allowed' : ''} transition-all duration-200`}
            disabled={isProcessing || !user}
          >
            <input {...getInputProps()} />
            <ArrowUpTrayIcon className="h-4 w-4" />
            <span>Upload files</span>
            <span className="text-xs text-purple-500 dark:text-purple-400">
              {user ? '(.txt, .md, .pdf, .doc, .docx)' : '(Sign in to upload)'}
            </span>
          </button>

          <button
            className={`inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
              bg-purple-50 text-purple-700 hover:bg-purple-100 
              dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30
              ${!user ? 'opacity-50 cursor-not-allowed' : ''}
              transition-all duration-200`}
            disabled={isProcessing || !user}
          >
            <ArrowUpTrayIcon className="h-4 w-4" />
            <span>Upload from Drive</span>
          </button>

          <div className="relative" ref={stylesMenuRef}>
            <button
              onClick={() => user && setIsStylesMenuOpen(!isStylesMenuOpen)}
              className={`inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                bg-purple-50 text-purple-700 hover:bg-purple-100 
                dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30
                ${!user ? 'opacity-50 cursor-not-allowed' : ''}
                transition-all duration-200`}
              disabled={!user}
            >
              <span>Format Mode</span>
              <svg
                className={`w-4 h-4 transition-transform duration-200 ${isStylesMenuOpen ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {isStylesMenuOpen && user && (
              <div className="absolute left-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-50 border border-purple-100 dark:border-gray-700">
                <div className="py-1">
                  <button className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30">
                    Academic Article
                  </button>
                  <button className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30">
                    Developer Documentation
                  </button>
                  <button className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30">
                    Business Document
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-3">
          {formattedText && (
            <button
              onClick={handleViewChanges}
              className="inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                bg-purple-50 text-purple-700 hover:bg-purple-100 
                dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30
                transition-all duration-200"
            >
              <ArrowsRightLeftIcon className="h-4 w-4" />
              <span>View Changes</span>
            </button>
          )}

          <button
            onClick={handleCopy}
            disabled={!formattedText}
            className="px-4 py-2 rounded-lg text-sm font-medium
              bg-purple-50 text-purple-700 hover:bg-purple-100 
              dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30
              disabled:bg-gray-100 disabled:text-gray-400 dark:disabled:bg-gray-800 dark:disabled:text-gray-600
              transition-all duration-200"
          >
            Copy
          </button>

          <div className="relative" ref={downloadMenuRef}>
            <button
              onClick={() => setIsDownloadMenuOpen(!isDownloadMenuOpen)}
              disabled={!formattedText}
              className="inline-flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                bg-purple-50 text-purple-700 hover:bg-purple-100 
                dark:bg-purple-900/20 dark:text-purple-300 dark:hover:bg-purple-900/30
                disabled:bg-gray-100 disabled:text-gray-400 dark:disabled:bg-gray-800 dark:disabled:text-gray-600
                transition-all duration-200"
            >
              Download
              <svg
                className={`w-4 h-4 transition-transform duration-200 ${isDownloadMenuOpen ? 'rotate-180' : ''}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>

            {isDownloadMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-50 border border-purple-100 dark:border-gray-700">
                <div className="py-1">
                  {user ? (
                    <>
                      <button
                        onClick={() => handleDownload('md')}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30"
                      >
                        Markdown (.md)
                      </button>
                      <button
                        onClick={() => handleDownload('pdf')}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30"
                      >
                        PDF (.pdf)
                      </button>
                      <button
                        onClick={() => handleDownload('docx')}
                        className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-purple-50 dark:hover:bg-purple-900/30"
                      >
                        Word Document (.docx)
                      </button>
                    </>
                  ) : (
                    <div className="px-4 py-2 text-sm text-gray-700 dark:text-gray-200 flex flex-col items-start">
                      <p className="mb-2">Sign in to unlock PDF, .docs, .docx, and .md downloads.</p>
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          const id_token = credentialResponse.credential;
                          if (id_token) {
                            axios.post(getApiUrl(API_CONFIG.ENDPOINTS.GOOGLE_AUTH), { id_token })
                              .then(res => {
                                login(res.data.user);
                                setIsDownloadMenuOpen(false);
                              })
                              .catch(error => {
                                console.error('Login Failed:', error);
                                alert('Google Login Failed. Please try again.');
                              });
                          }
                        }}
                        onError={() => {
                          console.error('Google Login Failed');
                          alert('Google Login Failed. Please try again.');
                        }}
                        useOneTap
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md px-2 py-1 transition-colors duration-200"
                          >
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                              <path
                                fill="#FFFFFF"
                                d="M44.5,20H24v8.5h11.9C34.6,33,30,36.4,24,36.4c-7.4,0-13.4-6-13.4-13.4S16.6,9.6,24,9.6c3.7,0,7.1,1.5,9.5,3.9l6.4-6.4C36.3,2.6,30.5,0,24,0C10.7,0,0,10.7,0,24s10.7,24,24,24c13,0,23.6-10.1,23.6-23.6C47.6,22.8,46.2,21.2,44.5,20z"
                              />
                            </svg>
                            <span>Sign In</span>
                          </button>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <AnimatedFormatButton 
            isProcessing={isProcessing}
            onClick={handleFormat}
            disabled={isProcessing}
          />
        </div>
      </div>
    </div>
  );
};

export default TopControls;
