import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';

const AnimatedFormatButton = ({ 
  isProcessing, 
  onClick, 
  disabled,
  className = '',
  processingText = 'Processing',
  idleText = 'Format'
}) => {
  const [dots, setDots] = useState('');
  
  useEffect(() => {
    if (isProcessing) {
      const interval = setInterval(() => {
        setDots(prev => prev.length >= 3 ? '' : prev + '.');
      }, 500);
      return () => clearInterval(interval);
    }
    setDots('');
  }, [isProcessing]);

  return (
    <button
      onClick={onClick}
      disabled={disabled || isProcessing}
      className={`
        relative overflow-hidden
        px-4 py-2
        rounded-lg text-sm font-medium
        transition-all duration-200
        ${disabled || isProcessing ? 
          'bg-purple-100 text-purple-400 dark:bg-purple-900/20 dark:text-purple-400/60 cursor-not-allowed' : 
          'bg-purple-600 text-white hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700'
        }
        focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800
        group
        ${className}
      `}
      type="button"
    >
      <div className="relative flex items-center justify-center h-5">
        <AnimatePresence mode="wait">
          {isProcessing ? (
            <motion.div
              key="processing"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="flex items-center gap-2 text-inherit"
            >
              <Loader2 className="w-4 h-4 animate-spin" />
              <span className="min-w-[80px] text-left">
                {processingText}{dots}
              </span>
            </motion.div>
          ) : (
            <motion.span
              key="idle"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="text-inherit"
            >
              {idleText}
            </motion.span>
          )}
        </AnimatePresence>
      </div>

      {/* Gradient shine effect */}
      <div 
        className={`
          absolute inset-0 
          bg-gradient-to-r from-transparent via-white/10 to-transparent
          translate-x-[-200%] group-hover:translate-x-[200%]
          transition-transform duration-1000 ease-in-out
          pointer-events-none
          rounded-lg
        `}
      />

      {/* Pulsing border effect when processing */}
      {isProcessing && (
        <div className="absolute inset-0 rounded-lg animate-pulse">
          <div className="absolute inset-0 rounded-lg border-2 border-purple-400/30 dark:border-purple-300/20" />
        </div>
      )}

      {/* Focus ring helper for accessibility */}
      <span className="sr-only">
        {isProcessing ? processingText : idleText} button
      </span>
    </button>
  );
};

// Wrapper component with keyboard shortcuts
const FormatButtonWithHotkey = (props) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'f') {
        e.preventDefault();
        if (!props.disabled && !props.isProcessing) {
          props.onClick();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [props.onClick, props.disabled, props.isProcessing]);

  return (
    <div className="relative group">
      <AnimatedFormatButton {...props} />
      
      {/* Keyboard shortcut tooltip */}
      <div className="
        absolute left-1/2 -translate-x-1/2 -top-9
        px-2 py-1
        bg-gray-800 dark:bg-gray-700
        text-white text-xs
        rounded shadow-lg
        opacity-0 group-hover:opacity-100
        transition-opacity duration-200
        pointer-events-none
        whitespace-nowrap
        before:content-['']
        before:absolute before:left-1/2 before:-ml-1 before:bottom-0
        before:w-2 before:h-2
        before:bg-gray-800 dark:before:bg-gray-700
        before:rotate-45 before:translate-y-1/2
      ">
        {navigator.platform.toLowerCase().includes('mac') ? '⌘' : 'Ctrl'} + F
      </div>
    </div>
  );
};

export default FormatButtonWithHotkey;