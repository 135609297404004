// File: docease/src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../config/api';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [usage, setUsage] = useState({ word_count: 0, days_until_reset: 7 });

  // Initialize user from localStorage on mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      if (parsedUser.id) {
        fetchUsage(parsedUser.id);
      }
    }
  }, []);

  const login = async (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));

    if (userData.id) {
      // Fetch usage data
      await fetchUsage(userData.id);
    }
  };

  const logout = () => {
    setUser(null);
    setUsage({ word_count: 0, days_until_reset: 7 });
    localStorage.removeItem('user');
  };

  const fetchUsage = async (user_id) => {
    try {
      const response = await axios.get(getApiUrl(API_CONFIG.ENDPOINTS.USAGE), {
        params: { user_id }
      });
      if (response.data) {
        setUsage({
          word_count: response.data.word_count,
          days_until_reset: response.data.days_until_reset
        });
      }
    } catch (error) {
      console.error('Error fetching usage:', error);
    }
  };

  const updateUsage = async (additionalWords) => {
    if (!user || !user.id) return;

    try {
      const response = await axios.post(getApiUrl(API_CONFIG.ENDPOINTS.USAGE), {
        user_id: user.id,
        words: additionalWords
      });
      if (response.status === 200) {
        // After updating, fetch the updated usage
        await fetchUsage(user.id);
      }
    } catch (error) {
      console.error('Error updating usage:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, usage, updateUsage, fetchUsage }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
