import React, { useState, useEffect } from 'react';
import TableOfContents from './TableOfContents';  // Changed from named to default import

const HeaderTracker = ({ formattedText, isOpen, toggleToC }) => {
  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState('');

  // Parse headers from markdown text
  useEffect(() => {
    if (!formattedText) {
      setHeadings([]);
      return;
    }

    const headers = formattedText
      .split('\n')
      .filter(line => line.startsWith('#'))
      .map(header => {
        const level = header.match(/^#+/)[0].length;
        const text = header.replace(/^#+\s+/, '');
        const id = text.toLowerCase().replace(/[^\w]+/g, '-');
        return { level, text, id };
      });

    setHeadings(headers);
  }, [formattedText]);

  // Track active header based on scroll
  useEffect(() => {
    const handleScroll = () => {
      const headingElements = [...document.querySelectorAll('h1, h2, h3')];
      
      for (const element of headingElements) {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setActiveId(element.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <TableOfContents 
      headings={headings}
      activeId={activeId}
      isOpen={isOpen}
      toggleToC={toggleToC}
      showGlow={false}
    />
  );
};

export default HeaderTracker;