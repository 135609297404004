import React from 'react';
import ReactMarkdown from 'react-markdown';

const TermsPage = () => {
  const termsOfService = `# Terms of Service

Last updated: December 02, 2024

## 1. Agreement to Terms

By accessing and using DocEase ("we," "our," or "us"), you agree to be bound by these Terms of Service and all applicable laws and regulations. If you disagree with any part of these terms, you may not use our service.

## 2. Service Description

DocEase provides AI-powered document formatting services. We offer:
- Document formatting and styling
- File conversion between various formats
- Google Drive integration for document access
- Document analysis and improvement suggestions

## 3. User Accounts

### 3.1 Account Creation
To use certain features of our service, you must create an account using Google Sign-In. You agree to:
- Provide accurate information
- Maintain the security of your account
- Accept responsibility for all activities under your account
- Never share your account credentials

### 3.2 Usage Limits
- Free accounts: Limited to 2,000 words per week
- Authenticated accounts: Limited to 5,000 words per week
- Premium accounts: Subject to separate terms

## 4. User Content

### 4.1 Content Ownership
You retain all rights to your documents. By using our service, you grant us the limited right to:
- Process your documents for formatting
- Store temporary copies necessary for processing
- Generate formatted versions of your content

### 4.2 Prohibited Content
You agree not to upload content that:
- Infringes on intellectual property rights
- Contains malware or harmful code
- Violates any applicable laws
- Contains offensive or inappropriate material

## 5. Service Usage

### 5.1 Acceptable Use
You agree to use the service only for:
- Legitimate document formatting needs
- Personal or business use as intended
- Purposes permitted by these terms and applicable laws

### 5.2 Prohibited Activities
You must not:
- Attempt to bypass usage limits
- Reverse engineer the service
- Use the service to harm others
- Interfere with service operation

## 6. Privacy and Data Protection

### 6.1 Data Processing
We process your data in accordance with our Privacy Policy. By using our service, you agree to:
- Our data collection practices
- Our data processing methods
- Our data retention policies

### 6.2 Google Drive Integration
When using Google Drive integration:
- We only access files you explicitly select
- We process files solely for formatting
- We maintain strict data security standards

## 7. Intellectual Property

### 7.1 Our Rights
All aspects of our service, including but not limited to:
- Software code
- User interface designs
- Logos and branding
- AI formatting algorithms
are protected by intellectual property rights and remain our property.

### 7.2 License to Use
We grant you a limited, non-exclusive license to use our service for its intended purpose.

## 8. Disclaimers and Limitations

### 8.1 Service Availability
We strive for 100% uptime but cannot guarantee:
- Uninterrupted service
- Error-free operation
- Specific results from formatting

### 8.2 Liability Limitations
We are not liable for:
- Indirect or consequential damages
- Lost profits or data
- Service interruptions
- Third-party actions

## 9. Changes to Terms

We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting. Continued use of the service constitutes acceptance of new terms.

## 10. Contact Information

For questions about these Terms, please contact:
- Email: support@docease.app

## 11. Governing Law

These terms are governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles.`;

  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <div className="prose dark:prose-invert max-w-none">
        <ReactMarkdown>
          {termsOfService}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default TermsPage;