// src/pages/HomePage.jsx

import React from 'react';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import HowItWorksSection from '../components/home/HowItWorksSection';
import WhyDocEaseSection from '../components/home/WhyDocEaseSection';
import TestimonialsSection from '../components/home/TestimonialsSection';
import PricingSection from '../components/home/PricingSection';
import Footer from '../components/layout/Footer';

const HomePage = () => {
  return (
    <div className="pt-16">
      <HeroSection />
      <WhyDocEaseSection />
      <FeaturesSection />
      <HowItWorksSection />
      <TestimonialsSection />
      <PricingSection />
      <Footer />
      </div>
  );
};

export default HomePage;
