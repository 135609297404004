// src/components/formatter/InputSection.jsx

import React, { useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const InputSection = ({ inputText, setInputText, isProcessing }) => {
  const inputRef = useRef(null);

  const getPlainText = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const wordCount = getPlainText(inputText).trim().split(/\s+/).filter(Boolean).length;

  return (
    <div className="flex flex-col h-full rounded-xl bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 mx-2">
      <div className="flex items-center justify-between px-6 py-3 border-b border-gray-200 dark:border-gray-700">
        <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200">Input Text</h2>
        <div className="flex items-center gap-2">
          <span className="px-3 py-1 text-sm font-medium text-purple-600 dark:text-purple-400 bg-purple-50 dark:bg-purple-900/30 rounded-full">
            Words: {wordCount}
          </span>
        </div>
      </div>

      <div className="flex-1 overflow-hidden bg-gray-50 dark:bg-gray-700/50">
        <ScrollSyncPane>
          <div className="h-full">
            <ReactQuill
              ref={inputRef}
              className="h-full flex flex-col"
              theme="snow"
              value={inputText}
              onChange={setInputText}
              readOnly={isProcessing}
              placeholder="Enter your text here..."
              modules={{
                toolbar: false,
                clipboard: {
                  matchVisual: false,
                }
              }}
              formats={[
                'bold', 'italic', 'underline', 'strike', 'link', 'image'
              ]}
            />
            <style jsx global>{`
              .quill {
                height: 100%;
                display: flex;
                flex-direction: column;
              }
              .quill .ql-container {
                flex: 1;
                overflow-y: auto;
                font-size: 16px;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
              }
              .quill .ql-editor {
                padding: 1.5rem;
                min-height: 100%;
                background-color: rgb(249 250 251); /* bg-gray-50 */
              }
              .dark .quill .ql-editor {
                background-color: rgba(55, 65, 81, 0.5); /* dark:bg-gray-700/50 */
              }
              .quill .ql-editor img {
                resize: both;
                overflow: auto;
                max-width: 100%;
              }
              .quill .ql-editor.ql-blank::before {
                font-style: normal;
                color: #9CA3AF;
              }
              .dark .quill .ql-editor {
                color: #E5E7EB;
              }
              .dark .quill .ql-editor.ql-blank::before {
                color: #6B7280;
              }
              .ql-container {
                border: none !important;
              }
            `}</style>
          </div>
        </ScrollSyncPane>
      </div>
    </div>
  );
};

export default InputSection;