import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  SunIcon,
  MoonIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowLeftStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { GoogleLogin } from '@react-oauth/google';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import API_CONFIG, { getApiUrl } from '../../config/api';

const AppNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [localWordCount, setLocalWordCount] = useState(0);
  const location = useLocation();
  const { user, login, logout, usage } = useContext(AuthContext);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    const storedTheme = localStorage.getItem('theme');
    if (storedTheme === 'dark') {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    }

    // Load local word count for unauthenticated users
    if (!user) {
      const storedCount = localStorage.getItem('currentWordCount');
      setLocalWordCount(parseInt(storedCount, 10) || 0);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [user]);

  const toggleDarkMode = () => {
    if (darkMode) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    }
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    logout();
  };

  const navLinkClasses = (path) =>
    `text-sm font-medium px-4 py-2 rounded-lg transition-all duration-200 ${
      location.pathname === path
        ? 'bg-purple-600 text-white dark:bg-purple-500 shadow-sm'
        : 'text-gray-700 hover:bg-purple-100 hover:text-purple-600 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white'
    }`;

  const getWordStats = () => {
    if (user) {
      const used = usage?.word_count || 0;
      const total = 5000;
      const remaining = Math.max(0, total - used);
      const percentage = Math.min(100, Math.max(0, (remaining / total) * 100));
      return { remaining, percentage, total };
    } else {
      const total = 2000;
      const remaining = Math.max(0, total - localWordCount);
      const percentage = Math.min(100, Math.max(0, (remaining / total) * 100));
      return { remaining, percentage, total };
    }
  };

  const WordCountDisplay = () => {
    const { remaining, percentage, total } = getWordStats();
    return (
      <div className="hidden lg:flex flex-col justify-center gap-2 bg-purple-50/80 dark:bg-gray-800/80 px-4 py-2 rounded-lg">
        <span className="text-sm text-gray-600 dark:text-gray-300 font-medium">
          Words Remaining: {remaining.toLocaleString()}/{total.toLocaleString()}
        </span>
        <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
          <div
            className="h-full rounded-full transition-all duration-300"
            style={{
              width: `${percentage}%`,
              backgroundColor:
                percentage > 50
                  ? '#8b5cf6'
                  : percentage > 20
                  ? '#f59e0b'
                  : '#ef4444',
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled ? 'bg-purple-50/80 dark:bg-gray-900/80 backdrop-blur-sm shadow-lg' : 'bg-purple-50 dark:bg-gray-900'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link
              to="/"
              className="text-xl font-bold text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-300 transition-colors"
            >
              DocEase
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className={navLinkClasses('/')}>
              Home
            </Link>
            <Link to="/formatter" className={navLinkClasses('/formatter')}>
              Formatter
            </Link>
            <Link to="/extension" className={navLinkClasses('/extension')}>
              Chrome Extension
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            <WordCountDisplay />

            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-all duration-300"
              aria-label="Toggle Dark Mode"
            >
              {darkMode ? (
                <SunIcon className="h-5 w-5" />
              ) : (
                <MoonIcon className="h-5 w-5" />
              )}
            </button>

            {user ? (
              <button
                onClick={handleLogout}
                className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
              >
                <ArrowRightStartOnRectangleIcon className="h-5 w-5 mr-2" />
                Sign Out
              </button>
            ) : (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const id_token = credentialResponse.credential;
                  if (id_token) {
                    axios
                      .post(getApiUrl(API_CONFIG.ENDPOINTS.GOOGLE_AUTH), {
                        id_token,
                      })
                      .then((res) => {
                        const userData = res.data.user;
                        login(userData);
                      })
                      .catch((error) => {
                        console.error('Login Failed:', error);
                        alert('Google Login Failed. Please try again.');
                      });
                  }
                }}
                onError={() => {
                  console.error('Google Login Failed');
                  alert('Google Login Failed. Please try again.');
                }}
                useOneTap
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    <ArrowLeftStartOnRectangleIcon className="h-5 w-5 mr-2" />
                    Sign In
                  </button>
                )}
              />
            )}

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="md:hidden p-2 rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-all duration-300"
              aria-label="Menu"
            >
              {isOpen ? (
                <XMarkIcon className="h-6 w-6" />
              ) : (
                <Bars3Icon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden bg-purple-50 dark:bg-gray-900 shadow-lg">
          <div className="px-4 py-4 space-y-3">
            <Link
              to="/"
              className={navLinkClasses('/')}
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/formatter"
              className={navLinkClasses('/formatter')}
              onClick={() => setIsOpen(false)}
            >
              Formatter
            </Link>
            <Link
              to="/extension"
              className={navLinkClasses('/extension')}
              onClick={() => setIsOpen(false)}
            >
              Chrome Extension
            </Link>
            <div className="py-2">
              <WordCountDisplay />
            </div>
            {user ? (
              <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-purple-100 hover:text-purple-700 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white rounded-lg transition-colors"
              >
                <ArrowRightStartOnRectangleIcon className="h-5 w-5 mr-2" />
                Sign Out
              </button>
            ) : (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const id_token = credentialResponse.credential;
                  if (id_token) {
                    axios
                      .post(getApiUrl(API_CONFIG.ENDPOINTS.GOOGLE_AUTH), {
                        id_token,
                      })
                      .then((res) => {
                        const userData = res.data.user;
                        login(userData);
                        setIsOpen(false);
                      })
                      .catch((error) => {
                        console.error('Login Failed:', error);
                        alert('Google Login Failed. Please try again.');
                      });
                  }
                }}
                onError={() => {
                  console.error('Google Login Failed');
                  alert('Google Login Failed. Please try again.');
                }}
                useOneTap
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="flex items-center w-full px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    <ArrowLeftStartOnRectangleIcon className="h-5 w-5 mr-2" />
                    Sign In
                  </button>
                )}
              />
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default AppNavbar;