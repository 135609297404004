// File: frontend/src/components/formatter/TableOfContents.jsx

import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const TableOfContents = ({ headings, activeId, isOpen, toggleToC, showGlow }) => {
  // Helper function to generate Roman numerals
  const toRoman = (num) => {
    const romanNumerals = [
      ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'],
      ['', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'],
      ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'],
    ];
    return num.toString().split('').map((digit, i, arr) => 
      romanNumerals[arr.length - i - 1][parseInt(digit)]).join('');
  };

  let h1Counter = 0;
  let h2Counter = 0;
  let h3Counter = 0;

  const resetSubCounters = (level) => {
    if (level === 1) {
      h2Counter = 0;
      h3Counter = 0;
    } else if (level === 2) {
      h3Counter = 0;
    }
  };

  return (
    <div className={`w-full h-full bg-gradient-to-br from-primary-50 to-white dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg transition-all duration-300 border border-primary-100 dark:border-primary-900 flex flex-col mx-2 ${
      showGlow ? 'animate-glow' : ''
    }`}>
      <div className="px-6 py-4 border-b border-primary-100 dark:border-primary-800/30 flex justify-between items-center bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm">
        <h2 className="text-lg font-bold text-primary-900 dark:text-primary-100">
          Table of Contents
        </h2>
        <button 
          onClick={toggleToC}
          className="p-1 hover:bg-primary-100 dark:hover:bg-primary-900/40 rounded-full transition-colors duration-200"
        >
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-primary-600 dark:text-primary-400" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-primary-600 dark:text-primary-400" />
          )}
        </button>
      </div>

      {isOpen && (
        <div className="flex-1 p-2 bg-white/30 dark:bg-gray-800/30 backdrop-blur-sm overflow-auto">
          <div className="overflow-y-auto" style={{
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgb(229 231 235) transparent'
          }}>
            <ul className="space-y-1">
              {headings.map((heading) => {
                resetSubCounters(heading.level);
                let prefix = '';
                let indentClass = '';
                let itemStyles = '';
                
                if (heading.level === 1) {
                  h1Counter++;
                  prefix = `${toRoman(h1Counter)}.`;
                  indentClass = '';
                  itemStyles = 'text-sm font-bold';
                } else if (heading.level === 2) {
                  h2Counter++;
                  prefix = `${String.fromCharCode(64 + h2Counter)}.`;
                  indentClass = 'ml-4';
                  itemStyles = 'text-sm font-semibold';
                } else if (heading.level === 3) {
                  h3Counter++;
                  prefix = `${h3Counter}.`;
                  indentClass = 'ml-12';
                  itemStyles = 'text-sm font';
                }

                return (
                  <li key={heading.id} className={`${indentClass}`}>
                    <a
                      href={`#${heading.id}`}
                      className={`
                        group flex items-center gap-3 px-3 py-1 rounded-lg
                        transition-all duration-200
                        ${activeId === heading.id 
                          ? 'bg-primary-100 dark:bg-primary-900/40 text-primary-900 dark:text-primary-100' 
                          : 'hover:bg-primary-50 dark:hover:bg-primary-900/20 text-primary-700 dark:text-primary-300'
                        }
                      `}
                    >
                      <span className={`
                        min-w-[2rem] font-medium
                        ${activeId === heading.id 
                          ? 'text-primary-600 dark:text-primary-400' 
                          : 'text-primary-400 dark:text-primary-600'
                        }
                        ${heading.level === 1 ? 'text-base' : 'text-sm'}
                      `}>
                        {prefix}
                      </span>
                      <span className={itemStyles}>
                        {heading.text}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableOfContents;
