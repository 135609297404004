// File: frontend/src/components/home/PricingSection.jsx

import { CheckCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { motion } from 'framer-motion';

const plans = [
  {
    name: 'Free',
    price: '$0',
    features: ['Basic Formatting', 'Limited Support', 'Community Access'],
  },
  {
    name: 'Pro',
    price: '$9.99/month',
    features: ['Advanced Formatting', 'Priority Support', 'Unlimited Exports'],
  },
  {
    name: 'Enterprise',
    price: 'Contact Us',
    features: ['Custom Solutions', 'Dedicated Support', 'Team Collaboration'],
  },
];

const PricingSection = () => {
  return (
    <section className="py-20 bg-white dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Choose Your Plan
        </motion.h2>
        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-6">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              className={`w-full md:w-1/3 bg-white dark:bg-gray-700 rounded-lg shadow-md p-6 mb-6 md:mb-0 ${
                plan.name === 'Pro' ? 'border-2 border-purple-600' : ''
              }`}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 text-center">
                {plan.name}
              </h3>
              <p className="text-center text-gray-600 dark:text-gray-300 text-3xl font-bold mb-6">
                {plan.price}
              </p>
              <ul className="mb-6 space-y-4">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <CheckCircleIcon className="h-6 w-6 text-purple-600 flex-shrink-0 mr-2" />
                    <span className="text-gray-700 dark:text-gray-300">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
              <button className="w-full px-4 py-2 bg-purple-600 text-white font-semibold rounded-md hover:bg-purple-700 transition duration-300">
                {plan.name === 'Enterprise' ? 'Contact Us' : 'Get Started'}
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
