import React from 'react';
import ReactMarkdown from 'react-markdown';

const PrivacyPolicyPage = () => {
  const privacyPolicy = `# Privacy Policy

Last updated: December 02, 2024

## 1. Introduction

Welcome to DocEase ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.

## 2. Information We Collect

### 2.1 Personal Data
We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:

- **Identity Data**: Includes name, username or similar identifier
- **Contact Data**: Includes email address
- **Technical Data**: Includes internet protocol (IP) address, browser type and version, time zone setting and location, operating system and platform
- **Usage Data**: Includes information about how you use our website and services
- **Document Data**: Includes the content of documents you upload for formatting

### 2.2 Google Drive Integration
When you use our Google Drive integration:
- We only access the documents you explicitly select
- We process these documents solely for formatting purposes
- We do not store your documents permanently
- We request only the minimum necessary permissions to provide our service

## 3. How We Use Your Information

We use your personal data only for:
- Providing our document formatting service
- Maintaining your account
- Improving our service
- Communicating with you about our service
- Ensuring the security of our platform

## 4. Data Security

We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way. We limit access to your personal data to those employees and agents who have a business need to know.

## 5. Data Retention

We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for. Documents uploaded for formatting are deleted immediately after processing.

## 6. Your Legal Rights

Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:
- Request access to your personal data
- Request correction of your personal data
- Request erasure of your personal data
- Object to processing of your personal data
- Request restriction of processing your personal data
- Request transfer of your personal data
- Right to withdraw consent

## 7. Third-Party Services

We use the following third-party services:
- Google Authentication for user login
- Google Drive API for document access
- OpenAI API for document formatting

## 8. Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this policy.

## 9. Contact Us

For questions about this Privacy Policy, please contact us at:
- Email: support@docease.app`;

  return (
    <div className="max-w-4xl mx-auto px-4 py-16">
      <div className="prose dark:prose-invert max-w-none">
        <ReactMarkdown>
          {privacyPolicy}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;